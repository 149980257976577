








































import {
  SfHeading,
  SfTable,
  SfCheckbox,
  SfButton,
  SfDivider,
  SfPrice,
  SfProperty,
  SfLink,
  SfImage,
  SfLoader,
} from "@storefront-ui/vue";
import {
  ref,
  computed,
  defineComponent,
  useRouter,
  useContext,
  onMounted,
} from "@nuxtjs/composition-api";

import cartGetters from "~/modules/checkout/getters/cartGetters";
import { useImage } from "~/composables";
import useMakeOrder from "~/modules/checkout/composables/useMakeOrder";
import useCart from "~/modules/checkout/composables/useCart";
import getShippingMethodPrice from "~/helpers/checkout/getShippingMethodPrice";
import { removeItem } from "~/helpers/asyncLocalStorage";
import { isPreviousStepValid } from "~/helpers/checkout/steps";
import useFtPaymentRedirect from "~/modules/fortytwo/payment/composables/useFtPaymentRedirect"
import GA4Checkout from '~/modules/fortytwo/googleanalytics4/components/GA4Checkout.vue';
import { useUser } from '~/modules/customer/composables/useUser';
import { useUiNotification } from '~/composables';
import FtButton from "~/components/customSFUI/vue/src/components/atoms/FtButton/FtButton.vue";
import { useApi } from '~/composables/useApi';
import { merge } from "lodash-es";

import type {
  BundleCartItem,
  ConfigurableCartItem,
  CartItemInterface,
} from "~/modules/GraphQL/types";

export default defineComponent({
  name: "ReviewOrderAndPaymentV1",
  components: {
    SfHeading,
    SfTable,
    SfCheckbox,
    SfButton,
    SfDivider,
    SfPrice,
    SfProperty,
    SfLink,
    SfImage,
    VsfPaymentProvider: () =>
      import("~/modules/checkout/components/V1/VsfPaymentProvider.vue"),
    GA4Checkout,
    SfLoader,
    FtButton,
  },
  setup() {
    const order = ref(null);
    const { query } = useApi();
    const { cart, load, setCart, getDiscountLabel } = useCart();
    const discountLabel = computed(() => getDiscountLabel() ?? '');
    const { make, loading, error } = useMakeOrder();
    // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
    const { app, $cookies, $recaptcha, $config } = useContext();
    const router = useRouter();
    const isPaymentReady = ref(false);
    const paymentMethod = ref("");
    const getAttributes = (product: ConfigurableCartItem) =>
      product.configurable_options || [];
    const getBundles = (product: BundleCartItem) =>
      product.bundle_options?.map((b) => b.values).flat() || [];
    const { paymentRedirect } = useFtPaymentRedirect();
    const { isAuthenticated } = useUser();
    const isDisabled = ref(false);

    const isQuotationAvailable = $cookies.get('quotation_available') ?? false;

    const isRecaptchaEnabled = ref<boolean>(typeof $recaptcha !== 'undefined' && Boolean($config.isRecaptcha));
    // const cartItem = ref({});
    onMounted(async () => {
      if (isRecaptchaEnabled.value){
        $recaptcha.init();
      }

      const validStep = await isPreviousStepValid("shipping");
      if (!validStep || !isAuthenticated.value) {
        await router.push(app.localePath("/checkout/shipping"));
      }
      if (!cart.value?.id) {
        await load();
      }
      // cartItem.value = cart.value;
    });

    const getRecaptchaInfo = async (isRecaptchaOn: boolean) : Promise<{ token: () => Promise<string> | null, cleanup: () => void }> => {
      if (isRecaptchaOn) {
        return { token: async () => await $recaptcha.execute(), cleanup: () => { $recaptcha.destroy(); } };
      }
      return { token: null, cleanup: () => {} };
    };

    const { send: sendNotification } = useUiNotification();
    
    // alert box prompt the message via cart parameter "error". 
    const onError = async (message) => {
        sendNotification({
          id: Symbol('checkout_error'),
          message: message,
          type: 'danger',
          icon: '',
          persist: false,
          title: 'Checkout Error',
        });
    };

    const processOrder = async () => {
      if (!paymentMethod.value) {
        await onError(app.i18n.t('Please specify payment method.'));
        return;
      }
      await router.push(app.localePath({ name: 'v1-order-review' }));
    };

    //generate quote graphql
    const generateQuoteQuery:any = ref({
      cart_id: ""
    });

    //delete quote graphql
    const deleteQuoteQuery:any = ref({
      url: ""
    });

    const quoteGraphqlQuery = `
      mutation {
        generateQuotePDF(input: {
              cart_id: "${app.$vsf.$magento.config.state.getCartId()}"
            }) {
              error
              message
              url
            }
          }
        `;  

        const generateQuote = async () => {
  isDisabled.value = true;

  try {
    const { data: { generateQuotePDF } } = await query<{ generateQuotePDF }>(quoteGraphqlQuery);
    generateQuoteQuery.value = merge(generateQuoteQuery.value, generateQuotePDF);

    const url = generateQuoteQuery.value?.url;
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const customFileName = `quotation_${day}-${month}-${year}.pdf`;

    console.log('Generated custom file name:', customFileName);

    if (url) {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const anchor = document.createElement('a');
      const objectURL = URL.createObjectURL(blob);
      
      anchor.href = objectURL;
      anchor.download = customFileName;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      
      URL.revokeObjectURL(objectURL);

      console.log('Download triggered');

      const deleteQuoteGraphqlQuery = `
        mutation {
          deleteQuote(input: {
                url: "${url}"
              }) {
                error
                message
              }
            }
          `;

      const { data: { deleteQuote } } = await query<{ deleteQuote }>(deleteQuoteGraphqlQuery);
      deleteQuoteQuery.value = merge(deleteQuoteQuery.value, deleteQuote);
      console.log(deleteQuoteQuery);
    } else {
      console.error('URL is empty');
    }
  } catch (error) {
    console.error('Error generating quote:', error);
  } finally {
    setTimeout(() => {
      isDisabled.value = false;
    }, 3000);
  }
};

    const discounts = computed(() => cartGetters.getDiscounts(cart.value));
    const hasDiscounts = computed(() => discounts.value.length > 0);
    const discountsAmount = computed(
      () => -1 * discounts.value.reduce((a, el) => el.value + a, 0)
    );

    const { getMagentoImage, imageSizes } = useImage();
    const getRowTotal = (product: CartItemInterface) =>
      cartGetters.getItemPrice(product).totalWithTax -
      cartGetters.getItemPrice(product).special;
    return {
      cart,
      cartGetters,
      discounts,
      hasDiscounts,
      discountsAmount,
      getShippingMethodPrice,
      isPaymentReady,
      loading,
      processOrder,
      generateQuote,
      generateQuoteQuery,
      deleteQuoteQuery,
      products: computed(() => cartGetters.getItems(cart.value)),
      selectedShippingMethod: computed(() =>
        cartGetters.getSelectedShippingMethod(cart.value)
      ),
      totals: computed(() => cartGetters.getTotals(cart.value)),
      getAttributes,
      getBundles,
      getMagentoImage,
      imageSizes,
      getRowTotal,
      paymentMethod,
      reloadCart: load, // reload cart when payment method is selected
      order,
      isDisabled,
      // cartItem,
      discountLabel,
      isAuthenticated,
      isQuotationAvailable
    };
  },

  head() 
  {
    return { 
      script: 
      [
        { 
          src:'https://na.gateway.spring.citi.com/static/checkout/checkout.min.js', 
    
        }
      ],
    }
  }

});
